<template>
  <div class="min-h-screen flex items-center justify-center">
    <div class="register-card sm:p-4 sm:grid grid-cols-2 items-center w-full">
      <div class="register-image sm:col-span-1 col-span-2"></div>
      <div class="xl:mx-24 lg:mx-16 md:mx-8 sm:mx-4 register-data" :class="$route.name === 'page-signup' ? 'signup-data' : 'login-data'">
        <p class="text-blue uppercase sm:text-3xl text-xl font-bold leading-tight sm:mb-7 mb-4">{{ $t('welcome to') }}</p>
        <img class="sm:h-12 h-8" src="@/assets/images/shuttle-logo.svg" alt="">
        <p class="text-gray leading-tight capitalize font-semibold text-lg sm:mt-5 mt-4">
          {{ $t('please create an account.') }}
        </p>
        <div class="grid grid-cols-2 sm:gap-4 gap-3 mt-7">
          <div class="xl:col-span-1 col-span-2">
            <p class="text-darkblue capitalize text-sm font-semibold leading-tight mb-2">{{ $t('Full Name') }}</p>
            <input v-validate="'required'" name="full name" :disabled="$route.query.name ? true : false" :class="$route.query.name ? 'register-disabled-input' : 'register-active-input'" class="register-input" type="text" :placeholder="$t('Full Name')" v-model="user.full_name" />
            <span class="text-danger text-xs" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
          </div>
          <div class="xl:col-span-1 col-span-2">
            <p class="text-darkblue capitalize text-sm font-semibold leading-tight mb-2">{{ $t('myshipblu Box No.') }}</p>
            <input v-validate="'required'" name="box number" :disabled="$route.query.box_number ? true : false" :class="$route.query.box_number ? 'register-disabled-input' : 'register-active-input'" class="register-input" type="text" :placeholder="$t('myshipblu Box No.')" v-model="user.box_number" />
            <span class="text-danger text-xs" v-show="errors.has('box number')">{{ errors.first('box number') }}</span>
          </div>
          <div class="col-span-2">
            <p class="text-darkblue capitalize text-sm font-semibold leading-tight mb-2">{{ $t('Email') }}</p>
            <input v-validate="'required|email'" name="email" :disabled="$route.query.email ? true : false" :class="$route.query.email ? 'register-disabled-input' : 'register-active-input'" class="register-input" type="text" :placeholder="$t('Email')" v-model="user.email" />
            <span class="text-danger text-xs" v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
          <div class="col-span-2">
            <p class="text-darkblue capitalize text-sm font-semibold leading-tight mb-2">{{ $t('Password') }}</p>
            <div class="relative">
              <input ref="password" v-validate="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Password')" name="password"
               class="register-input register-active-input" :type="showPassword ? 'text' : 'password'" :placeholder="$t('enter your password')" v-model="user.password"/>
              <span v-if="!showPassword" @click="showPassword = true" class="material-icons-outlined register-eye-icon">visibility</span>
              <span v-if="showPassword" @click="showPassword = false" class="material-icons-outlined register-eye-icon">visibility_off</span>
            </div>
            <span class="text-danger text-xs" v-show="errors.has('password')">{{ errors.first('password') }}</span>
          </div>
          <div class="col-span-2">
            <p class="text-darkblue capitalize text-sm font-semibold leading-tight mb-2">{{ $t('Reapeat Password') }}</p>
            <div class="relative">
              <input v-validate="{ required: true, confirmed:'password' ,regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }"
                name="confirm password" class="register-input register-active-input" :type="showRepeatPassword ? 'text' : 'password'" :placeholder="$t('repeat password')" />
              <span v-if="!showRepeatPassword" @click="showRepeatPassword = true" class="material-icons-outlined register-eye-icon">visibility</span>
              <span v-if="showRepeatPassword" @click="showRepeatPassword = false" class="material-icons-outlined register-eye-icon">visibility_off</span>
            </div>
            <span class="text-danger text-xs" v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
          </div>
          <div class="col-span-2 flex items-center">
            <input id="checkbox" class="register-checkbox-btn" type="checkbox">
            <label for="checkbox" class="ml-1 text-gray text-sm font-medium capitalize leading-tight cursor-pointer">{{ $t('remember me') }}</label>
          </div>
          <div class="col-span-2 text-center">
            <button @click="registerUser" class="sm:mt-7 mt-4 register-btn px-4 sm:py-3 py-2">{{ $t('Sign Up') }}</button>
            <p class="sm:mt-4 mt-2 leading-tight font-semibold text-sm capitalize">
              <span class="text-darkblue">{{ $t('have an account?') }}</span>
              <span class="ml-1 text-blue cursor-pointer" @click="changeForm('login')">{{ $t('log in') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendRequest } from '../../axios/requestHelper'

export default {
  data () {
    return {
      user: {
        email: this.$route.query.email,
        box_number: this.$route.query.box_number
      },
      showPassword: false,
      showRepeatPassword: false
    }
  },
  methods: {
    changeForm (formType) {
      if (formType === 'page-signup') {
        this.$router.push({
          name: 'page-signup'
        })
      } else {
        this.$router.push({
          name: 'page-login'
        })
      }
    },
    registerUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(this, `api/users/activate?email=${this.$route.query.email}&box_number=${this.$route.query.box_number}`, 'post', {password: this.user.password}, true,
            () => {
              this.$router.push({name: 'page-login'})
            }
          )
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/registration.scss"></style>